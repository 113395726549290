import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';

import { AuthStateService } from '../modules/shared/services/auth-state.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authStateService: AuthStateService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (
      !request.url.includes('donor-identity/api')
      || request.url.includes('refresh')
      || request.url.includes('sites')
    ) {
      return next.handle(request);
    }

    return this.authStateService
      .getJWT()
      .pipe(
        mergeMap((jwt) => next.handle(
          jwt
            ? request.clone({ setHeaders: { Authorization: `Bearer ${jwt}` } })
            : request,
        )),
      );
  }
}

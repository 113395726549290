import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  RouterModule,
  withComponentInputBinding,
} from '@angular/router';
import { BASE_PATH } from '@hae/api';
import { I18nModule } from '@hae/utils';

import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { ExpiredTokenInterceptor } from './app/interceptors/expired-token.interceptor';
import { JwtInterceptor } from './app/interceptors/jwt.interceptor';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      I18nModule.forRoot('Scanner'),
      RouterModule,
      MatDialogModule,
    ),
    { provide: BASE_PATH, useValue: window.location.origin },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ExpiredTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes, withComponentInputBinding()),
  ],
})
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));

import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./modules/scanner/scanner.routes').then((m) => m.routes),
  },

  {
    path: 'donor-queue',
    loadChildren: () => import('./modules/donor-queue/donor-queue.routes').then((m) => m.routes),
  },
  { path: '**', redirectTo: '/home' },
];

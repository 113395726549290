import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthStateService } from '../modules/shared/services/auth-state.service';

@Injectable()
export class ExpiredTokenInterceptor implements HttpInterceptor {
  public constructor(private authStateService: AuthStateService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (
      request.method === 'GET'
      && request.url.includes('donor-identity/api/tenants/auth/clients')
    ) {
      return EMPTY;
    }
    return next.handle(request).pipe(
      catchError((error) => {
        if (error?.status === 401 || error.status === 403) this.authStateService.logout(true);
        return throwError(() => error);
      }),
    );
  }
}
